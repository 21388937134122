import React, { useRef, useEffect } from 'react';
import styles from './Page.module.scss';
import { authorDetails, fetchPosts } from '../../hooks';
import { isBrowser } from 'react-device-detect';

type Props = {
  title?: string,
  children: React.Node
};

import moment from 'moment';

import { Menu, Button, Header, Card, Container, Image, Responsive, Item } from 'semantic-ui-react'

import { VariableSizeList as List } from 'react-window';

const spanCSS = {
  float: 'right',
  paddingLeft: '10px',
  paddingRight: '10px'
};

const h2Style = {
  marginTop: '0px',
  paddingLeft: '10px',
  paddingRight: '10px'
};

const aCSS = {
  paddingLeft: '5px',
  fontWeight: '550'
};

function mostRewardedAuthors(postResults, limit) {
  const authors = {};
  postResults.forEach((item) => {
    let pendingPayoutValue = 0;
    let totalPayoutValue = 0;
    let sumPayout = 0;
    const currentItem = item.node.frontmatter;

    if (currentItem.pending_payout_value && currentItem.total_payout_value) {
      totalPayoutValue = Number((currentItem.total_payout_value).replace(' SMOKE', ''));
      pendingPayoutValue = Number((currentItem.pending_payout_value).replace(' SMOKE', ''));
      sumPayout = totalPayoutValue + pendingPayoutValue;
    }

    if (currentItem.author in authors) {
      authors[currentItem.author] += sumPayout;
    } else {
      authors[currentItem.author] = sumPayout;
    }
  });

  const medals = { 1: '🥇', 2: '🥈', 3: '🥉' };
  const sortedAuthors = Object.keys(authors).sort((a, b) => authors[b] - authors[a]);

  const response = [];
  sortedAuthors.forEach((sortedAuthor, i) => {
    if (i < limit) {
      if (i < 3) {
        response.push(<span data-sal="slide-up" style={h2Style}>{`${medals[(i + 1).toString()]} `}</span>);
      } else {
        response.push(<span data-sal="slide-up" style={h2Style}>{`${i + 1} `}</span>);
      }
      response.push(<a data-sal="slide-up" href={`/${sortedAuthor}`} style={aCSS}>{sortedAuthor.toUpperCase()}</a>);
      response.push(<a data-sal="slide-up" href={`https://smoke.io/@${sortedAuthor}`} style={{ float: 'right' }}>🏠</a>);
      response.push(<span data-sal="slide-up" style={spanCSS}> {(authors[sortedAuthor]).toFixed(0)} SMOKE</span>);
      response.push(<br/>);
    }
  });
  return response;
}

function mostFeaturedAuthors(postResults, limit) {
  const authors = {};
  postResults.forEach((currentItem) => {
    const item = currentItem.node.frontmatter;
    if (item.author in authors) {
      authors[item.author] += 1;
    } else {
      authors[item.author] = 1;
    }
  });

  const medals = { 1: '🥇', 2: '🥈', 3: '🥉' };
  const sortedAuthors = Object.keys(authors).sort((a, b) => authors[b] - authors[a]);

  const response = [];
  sortedAuthors.forEach((sortedAuthor, i) => {
    if (i < limit) {
      if (i < 3) {
        response.push(<span data-sal="slide-up" style={h2Style}>{`${medals[(i + 1).toString()]} `}</span>);
      } else {
        response.push(<span data-sal="slide-up" style={h2Style}>{`${i + 1} `}</span>);
      }
      response.push(<a data-sal="slide-up" href={`/${sortedAuthor}`} style={aCSS}>{sortedAuthor.toUpperCase()}</a>);
      response.push(<a data-sal="slide-up" href={`https://smoke.io/@${sortedAuthor}`} style={{ float: 'right' }}>🏠</a>);
      response.push(<span data-sal="slide-up" style={spanCSS}> {authors[sortedAuthor]} posts</span>);
      response.push(<br/>);
    }
  });
  return response;
}

function trendingTags(postResults, limit) {
  const tags = {};
  let itr = 0;

  postResults.forEach((currentItem) => {
    if (itr < 150) {
      const item = currentItem.node.frontmatter;
      itr += 1;
      if (item.tags) {
        item.tags.forEach((tag) => {
          if (tag in tags) {
            tags[tag] += 1;
          } else {
            tags[tag] = 1;
          }
        });
      }
    }
  });

  const medals = { 1: '🥇', 2: '🥈', 3: '🥉' };
  const sortedTags = Object.keys(tags).sort((a, b) => tags[b] - tags[a]);

  const response = [];
  sortedTags.forEach((sortedTag, i) => {
    if (i < limit) {
      if (i < 3) {
        response.push(<span data-sal="slide-up" style={h2Style}>{`${medals[(i + 1).toString()]} `}</span>);
      } else {
        response.push(<span data-sal="slide-up" style={h2Style}>{`${i + 1} `}</span>);
      }
      response.push(<a data-sal="slide-up" href={`/tag/${sortedTag}`} style={aCSS}>{sortedTag.toUpperCase()}</a>);
      response.push(<span data-sal="slide-up" style={spanCSS}> {tags[sortedTag]} posts</span>);
      response.push(<br/>);
    }
  });
  return response;
}

function historicalTags(postResults, limit) {
  const tags = {};
  postResults.forEach((currentItem) => {
    const item = currentItem.node.frontmatter;
    if (item.tags) {
      item.tags.forEach((tag) => {
        if (tag in tags) {
          tags[tag] += 1;
        } else {
          tags[tag] = 1;
        }
      });
    }
  });

  const medals = { 1: '🥇', 2: '🥈', 3: '🥉' };
  const sortedTags = Object.keys(tags).sort((a, b) => tags[b] - tags[a]);

  const response = [];
  sortedTags.forEach((sortedTag, i) => {
    if (i < limit) {
      if (i < 3) {
        response.push(<span data-sal="slide-up" style={h2Style}>{`${medals[(i + 1).toString()]} `}</span>);
      } else {
        response.push(<span data-sal="slide-up" style={h2Style}>{`${i + 1} `}</span>);
      }
      response.push(<a data-sal="slide-up" href={`/tag/${sortedTag}`} style={aCSS}>{sortedTag.toUpperCase()}</a>);
      response.push(<span data-sal="slide-up" style={spanCSS}> {tags[sortedTag]} posts</span>);
      response.push(<br/>);
    }
  });
  return response;
}

function getAuthorTags(postResults) {
  const tags = {};
  postResults.forEach((item) => {
    item.node.frontmatter.tags.forEach((tag) => {
      if (tags[tag]) {
        tags[tag] += 1;
      } else {
        tags[tag] = 1;
      }
    });
  });

  const items = Object.keys(tags).map((key) => [key, tags[key]]);

  items.sort((a, b) => b[1] - a[1]);

  const response = [];
  items.forEach((currentTag, i) => {
    if (i < 50) {
      response.push(<Button basic size='tiny' style={{margin: '2px'}} href={`/tag/${currentTag[0]}`}>#{currentTag[0]} </Button>);
    }
  });
  return response;
}

function imgElement(imageSource, postSource, size) {
  return (
    <Image
      src={`https://images.weserv.nl/?url=${imageSource && imageSource.length ? imageSource[0] : 'https://www.smoke-indica.com/photo.jpg'}&w=${isBrowser ? 200 : 150}${size === 'large' ? '&h=380' : '&h=150'}${size === 'large' ? '&fit=inside' : '&fit=contain&cbg=transparent'}&output=webp`}
      as='a'
      size={size}
      href={postSource}
      alt='Main article image'
    />
  );
}

function extra(data, index) {
  return `${data[index].node.frontmatter.net_votes} users rewarded @${data[index].node.frontmatter.author} ${data[index].node.frontmatter.sortvalue} Smoke for this post.`;
}

const compactRow  = ({ index, data, style }) => (
  <Container>
    <Item.Group divided='vertically' style={{
      ...style,
      backgroundColor: index % 2 === 0 ? 'white' : '#F5F5F5',
      borderBottom: '1px dotted black',
      maxWidth: '100%',
      marginRight: '0px',
      display: 'flex',
      flexDirection: 'row',
      alignContent: 'center',
      overflow: 'auto',
      whiteSpace: 'break-wrap'
    }}>
      <Item>
        <Responsive minWidth={400}>
          <Item.Image>
            {imgElement(data[index].node.fields.images, data[index].node.fields.slug, 'medium')}
          </Item.Image>
        </Responsive>
          <Item.Content style={{paddingTop: '20px'}}>
            <Item.Header as='a' href={data[index].node.fields.slug} style={{wordWrap: 'break-word'}}>
                {data[index].node.frontmatter.title}
            </Item.Header>
            <Item.Meta>
              @<a href={`/${data[index].node.frontmatter.author}`} style={{ color: 'green', fontWeight: 'bold' }}>{data[index].node.frontmatter.author}</a> created this post on {moment(data[index].node.frontmatter.date).format('ll')}<br/>
            </Item.Meta>
            <Item.Description>
                {data[index].node.frontmatter.tags.map((tag) =>
                   <Button basic size='tiny' href={`/tag/${tag}`} className={styles['feed__compactButton']}>{tag}</Button>
                  )}
            </Item.Description>
            <Item.Extra>
              {extra(data, index)}
            </Item.Extra>
          </Item.Content>
      </Item>
    </Item.Group>
  </Container>
);

function getAuthor(authorResult, authorTitle) {
  let user;
  authorResult.forEach((author) => {
    const currentAuthor = author.node.frontmatter;
    if (currentAuthor.title === authorTitle) {
      user = author.node.frontmatter;
    }
  });
  return user;
}

const authorImg = {
  maxHeight: '125px',
  maxWidth: '125px',
  paddingTop: '8px',
  float: 'left',
  paddingRight: '10px'
};

const Page = ({ title, children }: Props) => {
  const postResults = fetchPosts();

  if (
    title
    && (
      title === 'Stats'
      || title === 'Historic Smoke tag usage stats'
      || title === 'Most rewarded authors stats'
      || title === 'Most featured authors stats'
      || title === 'Trending Smoke tags stats'
    )
  ) {
    let mostRewardedAuthorsRes;
    let mostFeaturedAuthorsRes;
    let trendingTagsRes;
    let historicalTagsRes;

    if (title === 'Stats') {
      try {
        mostRewardedAuthorsRes = mostRewardedAuthors(postResults, 5);
        mostFeaturedAuthorsRes = mostFeaturedAuthors(postResults, 5);
        trendingTagsRes = trendingTags(postResults, 5);
        historicalTagsRes = historicalTags(postResults, 5);
      } catch (error) {
        console.error(error);
        return;
      }

      try {
        return (
          <div /* ref={pageRef} */ className={styles['page']}>
            <Header style={{marginBottom: '5px'}}>
              <Menu secondary>
                <img
                  src={'/photo.jpg'}
                  width="45"
                  height="45"
                />
                <Menu.Item header href='/'>
                  Smoke-Indica.com
                </Menu.Item>
              </Menu>
              <Button.Group compact style={{marginTop: '5px'}}>
                <Button href='/' icon='home' />
                <Button href='/pages/about'>About</Button>
                <Button href='/pages/related'>Related</Button>
                <Button href='/pages/stats'>Stats</Button>
                <Button href='/pages/explorers'>Explorers</Button>
              </Button.Group>
            </Header>
            <div className={styles['page__inner']}>
              { title && <h1 className={styles['page__title']}>{title}</h1>}
              <div className={styles['page__body']}>
                {<Card fluid>
                  <Card.Content>
                    <Card.Header>
                      <Header as="h2">
                        Historical tag usage
                      </Header>
                    </Card.Header>
                    <Card.Meta style={{paddingTop: '5px'}}>
                      <Header as="h3">
                        {historicalTagsRes}
                      </Header>
                    </Card.Meta>
                  </Card.Content>
                  <Card.Content extra>
                    <Button size='tiny' style={{marginLeft:'10px', marginTop:'5px'}} href="/pages/historic_tags">More historic tags</Button>
                  </Card.Content>
                </Card>}

                {<Card fluid>
                  <Card.Content>
                    <Card.Header>
                      <Header as="h2">
                        Trending tags
                      </Header>
                    </Card.Header>
                    <Card.Meta style={{paddingTop: '5px'}}>
                      <Header as="h3">
                        {trendingTagsRes}
                      </Header>
                    </Card.Meta>
                  </Card.Content>
                  <Card.Content extra>
                    <Button size='tiny' style={{marginLeft:'10px', marginTop:'5px'}} href="/pages/trending_tags">More trending tags</Button>
                  </Card.Content>
                </Card>}

                {<Card fluid>
                  <Card.Content>
                    <Card.Header>
                      <Header as="h2">
                        Most featured authors
                      </Header>
                    </Card.Header>
                    <Card.Meta style={{paddingTop: '5px'}}>
                      <Header as="h3">
                        {mostFeaturedAuthorsRes}
                      </Header>
                    </Card.Meta>
                  </Card.Content>
                  <Card.Content extra>
                    <Button size='tiny' style={{marginLeft:'10px', marginTop:'5px'}} href="/pages/most_featured_authors">More featured authors</Button>
                  </Card.Content>
                </Card>}

                {<Card fluid>
                  <Card.Content>
                    <Card.Header>
                      <Header as="h2">
                        Most rewarded authors
                      </Header>
                    </Card.Header>
                    <Card.Meta style={{paddingTop: '5px'}}>
                      <Header as="h3">
                        {mostRewardedAuthorsRes}
                      </Header>
                    </Card.Meta>
                  </Card.Content>
                  <Card.Content extra>
                    <Button size='tiny' style={{marginLeft:'10px', marginTop:'5px'}} href="/pages/most_rewarded_authors">More top rewarded authors</Button>
                  </Card.Content>
                </Card>}

              </div>
            </div>
          </div>
        );
      } catch (error) {
        console.error(error);
      }
    } else if (title === 'Historic Smoke tag usage stats') {
      historicalTagsRes = historicalTags(postResults, 100);
      return (
        <div /* ref={pageRef} */ className={styles['page']}>
          <Header style={{marginBottom: '5px'}}>
            <Menu secondary>
              <img
                src={'/photo.jpg'}
                width="45"
                height="45"
              />
              <Menu.Item header href='/'>
                Smoke-Indica.com
              </Menu.Item>
            </Menu>
            <Button.Group compact style={{marginTop: '5px'}}>
              <Button href='/' icon='home' />
              <Button href='/pages/about'>About</Button>
              <Button href='/pages/related'>Related</Button>
              <Button href='/pages/stats'>Stats</Button>
              <Button href='/pages/explorers'>Explorers</Button>
            </Button.Group>
          </Header>
          <div className={styles['page__inner']}>
            <Button as="a" href="/pages/stats">Back to stats overview</Button>
            {<Card fluid>
              <Card.Content>
                <Card.Header>
                  <Header as="h1">
                    Historical tag usage statistics
                  </Header>
                </Card.Header>
                <Card.Meta style={{paddingTop: '5px'}}>
                  <Header as="h3">
                    {historicalTagsRes}
                  </Header>
                </Card.Meta>
              </Card.Content>
              <Card.Content extra>
                <Button size='tiny' style={{marginLeft:'10px', marginTop:'5px'}} as="a" href="/pages/stats">Back to stats overview</Button>
              </Card.Content>
            </Card>}
          </div>
        </div>
      );
    } else if (title === 'Most rewarded authors stats') {
      mostRewardedAuthorsRes = mostRewardedAuthors(postResults, 100);
      return (
        <div /* ref={pageRef} */ className={styles['page']}>
          <Header style={{marginBottom: '5px'}}>
            <Menu secondary>
              <img
                src={'/photo.jpg'}
                width="45"
                height="45"
              />
              <Menu.Item header href='/'>
                Smoke-Indica.com
              </Menu.Item>
            </Menu>
            <Button.Group compact style={{marginTop: '5px'}}>
              <Button href='/' icon='home' />
              <Button href='/pages/about'>About</Button>
              <Button href='/pages/related'>Related</Button>
              <Button href='/pages/stats'>Stats</Button>
              <Button href='/pages/explorers'>Explorers</Button>
            </Button.Group>
          </Header>
          <div className={styles['page__inner']}>
            <Button as="a" href="/pages/stats">Back to stats overview</Button>
            {<Card fluid>
              <Card.Content>
                <Card.Header>
                  <Header as="h1">
                    Most rewarded authors statistics
                  </Header>
                </Card.Header>
                <Card.Meta style={{paddingTop: '5px'}}>
                  <Header as="h3">
                    {mostRewardedAuthorsRes}
                  </Header>
                </Card.Meta>
              </Card.Content>
              <Card.Content extra>
                <Button size='tiny' style={{marginLeft:'10px', marginTop:'5px'}} as="a" href="/pages/stats">Back to stats overview</Button>
              </Card.Content>
            </Card>}
          </div>
        </div>
      );
    } else if (title === 'Most featured authors stats') {
      mostFeaturedAuthorsRes = mostFeaturedAuthors(postResults, 100);
      return (
        <div /* ref={pageRef} */ className={styles['page']}>
          <Header style={{marginBottom: '5px'}}>
            <Menu secondary>
              <img
                src={'/photo.jpg'}
                width="45"
                height="45"
              />
              <Menu.Item header href='/'>
                Smoke-Indica.com
              </Menu.Item>
            </Menu>
            <Button.Group compact style={{marginTop: '5px'}}>
              <Button href='/' icon='home' />
              <Button href='/pages/about'>About</Button>
              <Button href='/pages/related'>Related</Button>
              <Button href='/pages/stats'>Stats</Button>
              <Button href='/pages/explorers'>Explorers</Button>
            </Button.Group>
          </Header>
          <div className={styles['page__inner']}>
            <Button as="a" href="/pages/stats">Back to stats overview</Button>
            {<Card fluid>
              <Card.Content>
                <Card.Header>
                  <Header as="h1">
                    Most featured author statistics
                  </Header>
                </Card.Header>
                <Card.Meta style={{paddingTop: '5px'}}>
                  <Header as="h3">
                    {mostFeaturedAuthorsRes}
                  </Header>
                </Card.Meta>
              </Card.Content>
              <Card.Content extra>
                <Button size='tiny' style={{marginLeft:'10px', marginTop:'5px'}} as="a" href="/pages/stats">Back to stats overview</Button>
              </Card.Content>
            </Card>}
          </div>
        </div>
      );
    } else if (title === 'Trending Smoke tags stats') {
      trendingTagsRes = trendingTags(postResults, 100);
      return (
        <div /* ref={pageRef} */ className={styles['page']}>
          <Header style={{marginBottom: '5px'}}>
            <Menu secondary>
              <img
                src={'/photo.jpg'}
                width="45"
                height="45"
              />
              <Menu.Item header href='/'>
                Smoke-Indica.com
              </Menu.Item>
            </Menu>
            <Button.Group compact style={{marginTop: '5px'}}>
              <Button href='/' icon='home' />
              <Button href='/pages/about'>About</Button>
              <Button href='/pages/related'>Related</Button>
              <Button href='/pages/stats'>Stats</Button>
              <Button href='/pages/explorers'>Explorers</Button>
            </Button.Group>
          </Header>

          <div className={styles['page__inner']}>
            <Button as="a" href="/pages/stats">Back to stats overview</Button>
            {<Card fluid>
              <Card.Content>
                <Card.Header>
                  <Header as="h1">
                    Trending tags statistics
                  </Header>
                </Card.Header>
                <Card.Meta style={{paddingTop: '5px'}}>
                  <Header as="h3">
                    {trendingTagsRes}
                  </Header>
                </Card.Meta>
              </Card.Content>
              <Card.Content extra>
                <Button size='tiny' style={{marginLeft:'10px', marginTop:'5px'}} as="a" href="/pages/stats">Back to stats overview</Button>
              </Card.Content>
            </Card>}
          </div>
        </div>
      );
    }
  } else if (title && title.includes('profile page')) {
    const authorContents = authorDetails();
    const authorName = title.split('\'')[0];
    const authorResult = getAuthor(authorContents, authorName);

    let profileImage = '';

    if (!authorResult.profile_image) {
      profileImage = 'https://smoke-indica.com/css/images/smoke_user.webp';
    } else if (!profileImage.includes('steemitimages.com') && profileImage !== '/css/images/smoke_user.png') {
      profileImage = `//images.weserv.nl/?url=${authorResult.profile_image}&w=100&h=100&output=webp`;
    } else {
      profileImage = 'https://smoke-indica.com/css/images/smoke_user.webp';
    }

    let authorString = (((authorResult.title).slice(0, 1)).toUpperCase() + (authorResult.title).slice(1, ((authorResult.title).length)));
    authorString = authorString.length < 5
      ? authorString.toUpperCase()
      : authorString;

    const postResults = fetchPosts();
    const authorTags = getAuthorTags(postResults, authorResult.title);

    const tdIMG = {
      width: '15%'
    };
    const tdStats = {
      width: '85%'
    };
    const tableStyle = {
      width: '100%'
    };

    const filteredPosts = postResults.filter(post => post.node.frontmatter.author === authorResult.title);

    return (
      <div /* ref={pageRef} */ className={styles['page']}>
        <Header style={{marginBottom: '5px'}}>
          <Menu secondary>
            <img
              src={'/photo.jpg'}
              width="45"
              height="45"
            />
            <Menu.Item header href='/'>
              Smoke-Indica.com
            </Menu.Item>
          </Menu>
          <Button.Group compact style={{marginTop: '5px'}}>
            <Button href='/' icon='home' />
            <Button href='/pages/about'>About</Button>
            <Button href='/pages/related'>Related</Button>
            <Button href='/pages/stats'>Stats</Button>
            <Button href='/pages/explorers'>Explorers</Button>
          </Button.Group>
        </Header>
        <div className={styles['page__inner']}>

          {<Card fluid>
            <Card.Content>
              <Card.Header><Header as="h1">
                <a href={`https://smoke.io/@${authorResult.title}`}>
                  <img style={authorImg} src={profileImage} alt={`${authorResult.title}'s profile image'`} />
                </a>
                @<a href={`https://smoke.io/@${authorResult.title}`}>{authorString}</a>'s profile page
              </Header></Card.Header>
              <Card.Meta style={{paddingTop: '5px'}}>
                <Header as="h3">
                  Reputation: {authorResult.reputation}<br/>
                  Posts: {authorResult.post_count}
                </Header>
              </Card.Meta>
              <Card.Description>
                <Header as="h4">
                  {authorResult.about_author}
                </Header>
              </Card.Description>
            </Card.Content>
            <Card.Content extra>
              <b>Tags</b>: {authorTags}
            </Card.Content>
          </Card>}
          <Header as="h2">
            @<a href={`https://smoke.io/@${authorResult.title}`}>{authorString}</a>'s featured posts:
          </Header>
          <div className={styles['page__divdivdiv']}>
            <List
                height={isBrowser ? 750 : 650}
                itemCount={filteredPosts.length}
                rowCount={filteredPosts.length}
                itemData={filteredPosts}
                itemSize={() => (
                  isBrowser
                    ? 170
                    : 170
                )}
                width={() => (isBrowser
                  ? 300
                  : 300)
                }
              >
                {compactRow}
              </List>
          </div>
        </div>
      </div>
    );
  } else if (title && (title === 'About' || title === 'Related sites' || title === 'Contact' || title === 'Smoke Price')) {
    return (
      <div className={styles['page']}>
        <Header style={{marginBottom: '5px'}}>
          <Menu secondary>
            <img
              src={'/photo.jpg'}
              width="45"
              height="45"
            />
            <Menu.Item header href='/'>
              Smoke-Indica.com
            </Menu.Item>
          </Menu>
          <Button.Group compact style={{marginTop: '5px'}}>
            <Button href='/' icon='home' />
            <Button href='/pages/about'>About</Button>
            <Button href='/pages/related'>Related</Button>
            <Button href='/pages/stats'>Stats</Button>
            <Button href='/pages/explorers'>Explorers</Button>
          </Button.Group>
        </Header>

        {<Card fluid>
          <Card.Content>
            <Card.Header>
              <Header as="h1">
                {title}
              </Header>
            </Card.Header>
            <Card.Meta style={{paddingTop: '5px'}}>
              <Header as="h3">
                {children}
              </Header>
            </Card.Meta>
          </Card.Content>
        </Card>}
      </div>
    );
  } else {
    return (
      <div /* ref={pageRef} */ className={styles['page']}>
        <div className={styles['page__inner']}>
          { title && <h1 className={styles['page__title']}>{title}</h1>}
          <div className={styles['page__body']}>
            {children}
          </div>
        </div>
      </div>
    );
  }
};

export default Page;
